<template>
  <div class="app-container">
    <formPart ref="form" :query="query" :sup_this="sup_this"></formPart>
    <el-row style="margin-bottom: 20px">
      <el-col :span="6">
        <div class="flex-middle batchBtn">
          <batchChangeLogistics
            :selectionData="selectionData"
            :allSelectLength="allSelectLength"
            :selectionAll="selectionAll"
            :isIndeterminateData="isIndeterminateData"
            @handelCheckAllChange="handelCheckAllChange"
          ></batchChangeLogistics>
        </div>
      </el-col>
      <el-col :span="18" class="panginationClass">
        <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
      </el-col>
    </el-row>

    <CommonTable
      height="auto"
      :cols="cols"
      :infoData="data"
      :tableLoading="tableLoading"
      ref="changeLogisticsRef"
      @selection-change="handleSelectionChange"
      @select-all="selectAll"
    >
      <template #orderStatusSlot="{ scoped }">
        {{ ORDER_STATUS_LIST[scoped.orderStatus] }}
      </template>
      <template #beOverdueSlot="{ scoped }">
        <div v-if="parseInt(scoped.expressExpireTime) <= 3">
          {{ scoped.expressExpireTime }}
          <el-tag type="danger" size="mini" class="tixing">临近提醒</el-tag>
        </div>
        <div v-else>
          {{ scoped.expressExpireTime }}
        </div>
      </template>
      <template #isKnifeState="{ scoped: { isKnifeLayoutCreated } }">
        {{ KnifeLayout(isKnifeLayoutCreated) }}
      </template>
      <!-- 业务员 -->
      <template #userName="{ scoped: { userStatus, userName } }">
        {{ userName }}<span v-if="userStatus === 2" style="color: red">(已注销)</span>
      </template>
      <template #timeSlot="{ scoped }">
        <div>导入时间： {{ scoped.importTime }}</div>
        <div v-if="scoped.orderStatus === 42">排单时间：{{ scoped.sendTime || '暂无' }}</div>
        <div v-if="scoped.orderStatus === 70">
          <div>排单时间：{{ scoped.sendTime || '暂无' }}</div>
          <div>发货时间：{{ scoped.deliveryTime || '暂无' }}</div>
        </div>
        <div v-if="scoped.orderStatus === 80">取消时间：{{ scoped.cancelTime || '暂无' }}</div>
        <div v-if="scoped.orderStatus === 72">退货申请时间：{{ scoped.returnApplyTime || '暂无' }}</div>
        <div v-if="scoped.orderStatus === 73">
          <div>退货申请时间：{{ scoped.returnApplyTime || '暂无' }}</div>
          <div>退货完成时间：{{ scoped.returnCompleteTime || '暂无' }}</div>
        </div>
      </template>
      <template #operationSlot="{ scoped }">
        <changeStateBtn :data="scoped" :sup_this="sup_this"></changeStateBtn> <span class="line"> | </span>
        <Edit :data="scoped" :type="1" :id="scoped.id" :sup_this="sup_this" :orderStatus="orderStatus"> 编辑 </Edit>
        <span class="line"> | </span>
        <Edit :data="scoped" :isProTable="true" :id="scoped.id"> 查看详情 </Edit>
      </template>
    </CommonTable>
    <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
  </div>
</template>

<script>
import formPart from '../module/orderListModule/formPart'
import { ORDER_STATUS_LIST } from '@/utils/constant'
import pagination from '../module/orderListModule/pagination'
import changeStateBtn from '../module/orderListModule/changeStateBtn.vue'
import batchChangeLogistics from './batchChangeLogistics.vue'
import Edit from '../module/orderListModule/edit'
import { tableCols as cols } from './tableCols'
import { initDataMixin } from '@/mixins'
export default {
  name: 'changeLogistics',
  mixins: [initDataMixin],
  components: {
    formPart,
    pagination,
    changeStateBtn,
    Edit,
    batchChangeLogistics
  },
  computed: {
    KnifeLayout() {
      return (data) => {
        if (data == '0') {
          return '未全部生成'
        } else {
          return '已全部生成'
        }
      }
    }
  },
  watch: {
    page: {
      handler(newVal) {
        this.allSelectLength = 0
        this.selectionAll = false
        this.pageChangeValue = false
      },
      deep: true
    },
    size: {
      handler(newVal) {
        this.allSelectLength = 0
        this.selectionAll = false
        this.pageChangeValue = false
      },
      deep: true
    }
  },
  data() {
    return {
      sup_this: this,
      url: '/externaladmin/orderService/externalOrder/orderList',
      method: 'post',
      query: {
        orderItems: [
          {
            asc: false,
            column: 'order_time'
          },
          {
            asc: false,
            column: 'id'
          }
        ],
        isReturnRelatedData: 1,
        isGetAllOrder: 1
      },
      cols,
      orderStatus: '0',
      selectionData: [],
      allSelectLength: 0,
      selectionAll: false,
      isIndeterminateData: false,
      pageChangeValue: true,
      ORDER_STATUS_LIST
    }
  },
  methods: {
    handelCheckAllChange() {
      this.$refs.changeLogisticsRef.table.toggleAllSelection()
    },
    // 查看勾选项
    handleSelectionChange(selection) {
      this.selectionData = selection
      //   console.log('selection', selection)
      if (selection.length === this.data.length) {
        // console.log('this.data.length', this.data)
        if (this.pageChangeValue) {
          this.selectionAll = true
        }
      } else {
        this.selectionAll = false
      }
      if (selection.length !== this.data.length && selection.length !== 0) {
        this.isIndeterminateData = true
      } else {
        this.isIndeterminateData = false
      }
      this.pageChangeValue = true
    },
    // 勾选表格全选
    selectAll(selection) {
      this.allSelectLength = selection.length
    }
  }
}
</script>

<style lang="scss" scoped>
.line {
  color: #ccc;
}
.panginationClass {
  text-align: right;
}
.flex-middle {
  line-height: 48px;
}
</style>
