var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container"},[_c('formPart',{ref:"form",attrs:{"query":_vm.query,"sup_this":_vm.sup_this}}),_c('el-row',{staticStyle:{"margin-bottom":"20px"}},[_c('el-col',{attrs:{"span":6}},[_c('div',{staticClass:"flex-middle batchBtn"},[_c('batchChangeLogistics',{attrs:{"selectionData":_vm.selectionData,"allSelectLength":_vm.allSelectLength,"selectionAll":_vm.selectionAll,"isIndeterminateData":_vm.isIndeterminateData},on:{"handelCheckAllChange":_vm.handelCheckAllChange}})],1)]),_c('el-col',{staticClass:"panginationClass",attrs:{"span":18}},[_c('PaginationBar',{attrs:{"page":_vm.page,"size":_vm.size,"total":_vm.total},on:{"refreshTableEventFun":_vm.refreshTableEventFun}})],1)],1),_c('CommonTable',{ref:"changeLogisticsRef",attrs:{"height":"auto","cols":_vm.cols,"infoData":_vm.data,"tableLoading":_vm.tableLoading},on:{"selection-change":_vm.handleSelectionChange,"select-all":_vm.selectAll},scopedSlots:_vm._u([{key:"orderStatusSlot",fn:function(ref){
var scoped = ref.scoped;
return [_vm._v(" "+_vm._s(_vm.ORDER_STATUS_LIST[scoped.orderStatus])+" ")]}},{key:"beOverdueSlot",fn:function(ref){
var scoped = ref.scoped;
return [(parseInt(scoped.expressExpireTime) <= 3)?_c('div',[_vm._v(" "+_vm._s(scoped.expressExpireTime)+" "),_c('el-tag',{staticClass:"tixing",attrs:{"type":"danger","size":"mini"}},[_vm._v("临近提醒")])],1):_c('div',[_vm._v(" "+_vm._s(scoped.expressExpireTime)+" ")])]}},{key:"isKnifeState",fn:function(ref){
var isKnifeLayoutCreated = ref.scoped.isKnifeLayoutCreated;
return [_vm._v(" "+_vm._s(_vm.KnifeLayout(isKnifeLayoutCreated))+" ")]}},{key:"userName",fn:function(ref){
var ref_scoped = ref.scoped;
var userStatus = ref_scoped.userStatus;
var userName = ref_scoped.userName;
return [_vm._v(" "+_vm._s(userName)),(userStatus === 2)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("(已注销)")]):_vm._e()]}},{key:"timeSlot",fn:function(ref){
var scoped = ref.scoped;
return [_c('div',[_vm._v("导入时间： "+_vm._s(scoped.importTime))]),(scoped.orderStatus === 42)?_c('div',[_vm._v("排单时间："+_vm._s(scoped.sendTime || '暂无'))]):_vm._e(),(scoped.orderStatus === 70)?_c('div',[_c('div',[_vm._v("排单时间："+_vm._s(scoped.sendTime || '暂无'))]),_c('div',[_vm._v("发货时间："+_vm._s(scoped.deliveryTime || '暂无'))])]):_vm._e(),(scoped.orderStatus === 80)?_c('div',[_vm._v("取消时间："+_vm._s(scoped.cancelTime || '暂无'))]):_vm._e(),(scoped.orderStatus === 72)?_c('div',[_vm._v("退货申请时间："+_vm._s(scoped.returnApplyTime || '暂无'))]):_vm._e(),(scoped.orderStatus === 73)?_c('div',[_c('div',[_vm._v("退货申请时间："+_vm._s(scoped.returnApplyTime || '暂无'))]),_c('div',[_vm._v("退货完成时间："+_vm._s(scoped.returnCompleteTime || '暂无'))])]):_vm._e()]}},{key:"operationSlot",fn:function(ref){
var scoped = ref.scoped;
return [_c('changeStateBtn',{attrs:{"data":scoped,"sup_this":_vm.sup_this}}),_vm._v(" "),_c('span',{staticClass:"line"},[_vm._v(" | ")]),_c('Edit',{attrs:{"data":scoped,"type":1,"id":scoped.id,"sup_this":_vm.sup_this,"orderStatus":_vm.orderStatus}},[_vm._v(" 编辑 ")]),_c('span',{staticClass:"line"},[_vm._v(" | ")]),_c('Edit',{attrs:{"data":scoped,"isProTable":true,"id":scoped.id}},[_vm._v(" 查看详情 ")])]}}])}),_c('PaginationBar',{attrs:{"page":_vm.page,"size":_vm.size,"total":_vm.total},on:{"refreshTableEventFun":_vm.refreshTableEventFun}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }